* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-user-select: none;
          user-select: none;
}
body{
  background-image: url(/static/media/background.bb7b6636.jpg);
  margin: 0px;
  padding: 0px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-tap-highlight-color: transparent;
}

ol, ul {
  list-style: none;
}

p, h1, h2, h3, h4, h5, h6, a {
  font-family: Arial, sans-serif;
  font-weight: normal;
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:focus, select:focus, textarea:focus, button:focus {
  outline: none;
}

button {
  border: none;
}





/* ---Main--- */
.main {
  float: left;
  height: calc(100% - 70px);
  overflow: auto;
  margin: 20px 40px 90px 40px;
  position: relative;
}
.notebox{
  color: white;
  padding: 10px;
  background-color: #0e0f10e0;
}

/* ---Small Screen--- */

@media screen and (max-width: 800px) {
  .main {
    width: 100%;
    margin: 10px 0;
  }

  .rightside {
    width: 70%;
    position: absolute;
    top: 70px;
    right: -100%;
    transition: right 1s;
    height: 100%;
    border-left: 1px solid gray;
    background: #cbcbcb;
  }

  .rightsideShow {
    right: 0;
    height: 100%;
    width: 70%;
    position: absolute;
    top: 70px;
    transition: right 1s;
    border-left: 1px solid #949494;
    background: #cbcbcb;
  }

  .sidebar {
    margin: 0;
    width: 100%;
    border-top: 1px solid #327ba9;
  }

  .sidebar__box {
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.15)
  }

  .header__options {
    height: calc(100% - 70px);
    width: 350px;
    position: absolute;
    right: 0;
    bottom: -260px;
  }

  .header__options__icon {
    color: white;
    font-size: 30px;
    height: 70px;
  }

  .header__options__icon:hover {
    cursor: pointer;
  }

}

/* ---Stats Box--- */

.statbox__title {
  font-size: 18px;
  color: #6a6c75;
  margin-bottom: 0px;
  position: relative;
  top: 0px;
  transition: color 0.5s 0.5s, margin-bottom 0.5s 0.5s, top 0.5s 0.5s;
}

.statbox__value {
  display: inline-block;
  font-size: 14px;
  color: #0c87d6;
  position: relative;
  top: 0px;
  transition: color 0.5s 0.5s, font-size 0.5s 0.5s, line-height 0.5s 0.5s, top 0.5s 0.5s;
}

.statbox__unit {
  display: inline-block;
  font-size: 14px;
  color: #0c87d6;
  position: relative;
  top: 0px;
  transition: color 0.5s 0.5s, font-size 0.5s 0.5s, line-height 0.5s 0.5s, top 0.5s 0.5s;
}

.stats {
  height: 60px;
  width: 100%;
  border-bottom: 2px solid lightblue;
  position: absolute;
  top: 290px;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 0;
  transition: background 0.7s 0.8s, height 0.5s 0.5s, padding 0.5s 0.5s, margin 0.5s 0.5s, z-index 0s 1s
}

.statsCompleted {
  background: #dcdcdc;
  height: 300px;
  margin: -100px auto;
  padding: 40px;
  display: flex;
  align-items: center;
  z-index: 1;
  transition: background 1s, height 0.7s 0.3s, padding 0.5s 0.5s, margin 0.5s 0.5s;
}

.statsCompleted .statbox__title {
  color: black;
  margin-bottom: 5px;
  position: relative;
  top: -40px;
  transition: all 0.5s 0.5s;
}

.statsCompleted .statbox__value, .statsCompleted .statbox__unit {
  color: #7dae6a;
  font-size: 35px;
  line-height: 50px;
  position: relative;
  top: -40px;
  transition: all 0.5s 0.5s;
}

/* ---Results Box--- */
.resultsTitleHidden {
  position: absolute;
  top: 0;
  color: white;
  background: #61a75d;
  font-size: 14px;
  font-weight: 900;
  line-height: 35px;
  font-family: Arial;
  left: 0;
  right: 0;
  width: 100%;
  padding-left: 20px;
  opacity: 0;
  transition: all 1s;
}

.resultsTitleShow {
  opacity: 1;
  transition: opacity 0.5s 1s;
}

.resultsOptionsHidden {
  position: absolute;
  bottom: 0;
  color: white;
  font-size: 30px;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s 1s, all 1s;
  text-align: center;
  line-height: 70px;
}

.resultsOptionsShow {
  position: absolute;
  bottom: 0;
  color: white;
  font-size: 30px;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s 1s;
  text-align: center;
  line-height: 70px;
}

.results__options__box {
  display: inline-block;
  width: 25%;
  color: #8a8f92;
  background: #d0d0d0;
  font-size: 24px;
  transition: all 0.2s;
}

.results__options__boxUnavailable {
  display: inline-block;
  width: 25%;
  color: #bfbfbf;
  background: #d0d0d0;
  font-size: 24px;
  transition: all 0.2s;
}

.results__options__box:hover {
  background: #c0c0c0;
  cursor: pointer;
  color: #72777a;
  transition: all 0.1s;
}

/* ---Screen--- */
.screen {
  height: 300px;
  width: 100%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  font-size: 18px;
  line-height: 30px;
  letter-spacing: .05ex;
  color: #8f8f8f;
  overflow: hidden;
  font-family: 'Roboto Mono', monospace;
  font-style: italic;
  position: relative;
}

.screen__inner__wrapper {
  height: 270px;
  padding: 0 30px;
  border-top: 30px solid gainsboro;
  overflow: hidden;
  opacity: 0;
  
}

.screen__inner__wrapperFadeIn {
  height: 270px;
  padding: 0 30px;
  font-family: 'Roboto Mono', monospace;
  overflow: hidden;
  opacity: 18;
  font-size: 1px;
  transition: opacity 1s;
  font-size: 20px;
}

.screen__inner__num {
  text-align: center;
  width: 100px;
  margin: auto;
}

.screen__inner__sym {
  font-size: 20px;
  word-spacing: 20px;
  line-height: 40px;
}

.progressMeter {
  border: 2px solid #a4b9d3;
  border-radius: 200px;
  position: absolute;
  display: block;
  padding: 0 5px 0 5px;
  bottom: 4px;
  right: 15px;
  font-size: 14px;
  color: #a4b9d3;
}


.completedText {
  color: #00652c;
}

.progressMarker {
  position: relative;
  top: 5px;
  left: 1px;
}

.progressMarker::before {
  content: ' ';
  height: 20px;
  position: absolute;
  font-style: initial;
  border-left: 1px solid #a7a6a6;
}

.progressMarkerNum::before {
  content: ' ';
  height: 25px;
  position: absolute;
  font-style: initial;
  border-left: 1px solid #a7a6a6;
}

.progressMarkerSym::before {
  content: ' ';
  height: 30px;
  position: absolute;
  font-style: initial;
  border-left: 1px solid #a7a6a6;
}

.remainingText::first-letter {
  color: blue
}

.keyboard {
  background: #0e0f10e0;
  margin: 30px auto;
  padding: 9px;
  text-align: center;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  width: 825px;
  box-sizing: border-box;
}

.keyboard_sidebar{
  display: flex;
}

.keyboard__key {
  height: 50px;
  width: 50px;
  background: #35363a;
  display: inline-grid;
  text-align: center;
  margin: 3px;
  padding: 6px;
  color: #aba7a7;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  border-bottom: 3px solid #2b2b2b;
  position: relative
}
.keyboard__highlightedKey {
  -webkit-animation: incorrect 2s;
          animation: incorrect 2s
}

@-webkit-keyframes incorrect {
  from {
    background: #4c2929;
    border-bottom: 3px solid #601c1c;
  }
  to {
    background: #35363a;
    border-bottom: 3px solid #2b2b2b;
  }
}

@keyframes incorrect {
  from {
    background: #4c2929;
    border-bottom: 3px solid #601c1c;
  }
  to {
    background: #35363a;
    border-bottom: 3px solid #2b2b2b;
  }
}

.keyboard__capsOn {
  box-shadow: 0 0 2px 0 #ff9898;
}

.keyboard__width-plusHalf {
  width: 70px;
}

.keyboard__width-threeQuarts {
  width: 82px;
}

.keyboard__width-plusOne {
  width: 107px;
}

.keyboard__spacebar {
  width: 300px;
  margin: 3px auto;
}

.keyboard__correctKey {
  box-shadow: 0 0 10px 0 #98a9ff;
}



/* ---Sidebar--- */
.rightside {
  float: left;
  width: 350px;
  right: 0;
  margin-top: 40px;
}
.thumpin{
  text-align: center;
}
.rightsideShow {
  background: #2b2c30;
  float: left;
  height: calc(100% - 70px);
  width: 350px;
}


.sidebar__box {
  margin-bottom: 20px;
  box-shadow: -5px 13px 28px rgb(247 247 247 / 16%), 0 10px 10px rgb(0 0 0 / 22%);
}

.sidebar__generator__input {
  height: 50px;
  border: none;
  padding: 20px;
  font-size: 20px;
  letter-spacing: 6px;
  width: 80%;
  display: inline-block;
  vertical-align: top;
  background: #dcdcdc;
  color: #2b2c30;
}
.sidebar__generator__input::-webkit-input-placeholder {
  letter-spacing: 0px;
  color: #9b9da8;
  font-size: 16px;
}
.sidebar__generator__input::placeholder {
  letter-spacing: 0px;
  color: #9b9da8;
  font-size: 16px;
}

.sidebar__generator__button {
  width: 20%;
  height: 50px;
  display: inline-block;
  vertical-align: top;
  font-size: 30px;
  background: #0e69a4;
  color: #fff;
  transition: 0.2s;
}
.sidebar__generator__button:hover {
  cursor: pointer;
  background: #0c5a8e;
  transition: 0.1s;
}

.sidebar__item {
  height: 50px;
  background: #dcdcdc;
  border-top: 1px solid #cbcbcb;
  transition: 0.1s;
}
.sidebar__item:hover {
  background: #cecece;
  cursor: pointer;
  transition: 0.2s;
}

.sidebar__item__p {
  padding: 2px;
  padding-left: 20px;
  color: #2b2c30;
}

.sidebar__switch__option {
  height: 50px;
  display: inline-block;
  width: 33.333%;
  padding: 2px;
  text-align: center;
  background: #dcdcdc;
  transition: 0.1s;
  border-right: 1px solid #cbcbcb;
}
.sidebar__switch__option:hover {
  cursor: pointer;
  background: #cecece;
  transition: 0.1s;
}
.sidebar__switch__option .sidebar__swith {
  height: 40px;
}

.sidebar__tile__h1 {
  background: #4bff0026;
  color: #fff;
  font-size: 14px;
  font-weight: 900;
  line-height: 35px;
  padding: 0 20px;
}

.sidebar {
  width: calc(100% - 40px);
}

.statbox {
  float: left;
  height: 60px;
  width: 25%;
  text-align: center;
  line-height: 24px;
  padding: 8px;
}

/* ---Main--- */
.main {
  float: left;
  height: calc(100% - 70px);
  overflow: auto;
  margin: 20px 40px 90px 40px;
  position: relative;
}
.notebox{
  color: white;
  padding: 10px;
  background-color: #0e0f10e0;
}


/* ---Small Screen--- */

@media screen and (max-width: 800px) {
  .main {
    width: 100%;
    margin: 10px 0;
  }

  .rightside {
    width: 70%;
    position: absolute;
    top: 70px;
    right: -100%;
    transition: right 1s;
    height: 100%;
    border-left: 1px solid gray;
    background: #cbcbcb;
  }

  .rightsideShow {
    right: 0;
    height: 100%;
    width: 70%;
    position: absolute;
    top: 70px;
    transition: right 1s;
    border-left: 1px solid #949494;
    background: #cbcbcb;
  }

  .sidebar {
    margin: 0;
    width: 100%;
    border-top: 1px solid #327ba9;
  }

  .sidebar__box {
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.15)
  }

  .header__options {
    height: calc(100% - 70px);
    width: 350px;
    position: absolute;
    right: 0;
    bottom: -260px;
  }

  .header__options__icon {
    color: white;
    font-size: 30px;
    height: 70px;
  }

  .header__options__icon:hover {
    cursor: pointer;
  }

}

/* ---Stats Box--- */

.statbox__title {
  font-size: 18px;
  color: #6a6c75;
  margin-bottom: 0px;
  position: relative;
  top: 0px;
  transition: color 0.5s 0.5s, margin-bottom 0.5s 0.5s, top 0.5s 0.5s;
}

.statbox__value {
  display: inline-block;
  font-size: 14px;
  color: #0c87d6;
  position: relative;
  top: 0px;
  transition: color 0.5s 0.5s, font-size 0.5s 0.5s, line-height 0.5s 0.5s, top 0.5s 0.5s;
}

.statbox__unit {
  display: inline-block;
  font-size: 14px;
  color: #0c87d6;
  position: relative;
  top: 0px;
  transition: color 0.5s 0.5s, font-size 0.5s 0.5s, line-height 0.5s 0.5s, top 0.5s 0.5s;
}

.stats {
  height: 60px;
  width: 100%;
  border-bottom: 2px solid lightblue;
  position: absolute;
  top: 290px;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 0;
  transition: background 0.7s 0.8s, height 0.5s 0.5s, padding 0.5s 0.5s, margin 0.5s 0.5s, z-index 0s 1s
}

.statsCompleted {
  background: #dcdcdc;
  height: 300px;
  margin: -100px auto;
  padding: 40px;
  display: flex;
  align-items: center;
  z-index: 1;
  transition: background 1s, height 0.7s 0.3s, padding 0.5s 0.5s, margin 0.5s 0.5s;
}

.statsCompleted .statbox__title {
  color: black;
  margin-bottom: 5px;
  position: relative;
  top: -40px;
  transition: all 0.5s 0.5s;
}

.statsCompleted .statbox__value, .statsCompleted .statbox__unit {
  color: #7dae6a;
  font-size: 35px;
  line-height: 50px;
  position: relative;
  top: -40px;
  transition: all 0.5s 0.5s;
}

/* ---Results Box--- */
.resultsTitleHidden {
  position: absolute;
  top: 0;
  color: white;
  background: #61a75d;
  font-size: 14px;
  font-weight: 900;
  line-height: 35px;
  font-family: Arial;
  left: 0;
  right: 0;
  width: 100%;
  padding-left: 20px;
  opacity: 0;
  transition: all 1s;
}

.resultsTitleShow {
  opacity: 1;
  transition: opacity 0.5s 1s;
}

.resultsOptionsHidden {
  position: absolute;
  bottom: 0;
  color: white;
  font-size: 30px;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s 1s, all 1s;
  text-align: center;
  line-height: 70px;
}

.resultsOptionsShow {
  position: absolute;
  bottom: 0;
  color: white;
  font-size: 30px;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s 1s;
  text-align: center;
  line-height: 70px;
}

.results__options__box {
  display: inline-block;
  width: 25%;
  color: #8a8f92;
  background: #d0d0d0;
  font-size: 24px;
  transition: all 0.2s;
}

.results__options__boxUnavailable {
  display: inline-block;
  width: 25%;
  color: #bfbfbf;
  background: #d0d0d0;
  font-size: 24px;
  transition: all 0.2s;
}

.results__options__box:hover {
  background: #c0c0c0;
  cursor: pointer;
  color: #72777a;
  transition: all 0.1s;
}

/* ---Screen--- */
@font-face {
  font-family: preetinormal;
  src: url(/static/media/preetynormal.7e34e69a.otf) format("opentype");
}

.screen_nepali{
  height: 300px;
  width: 100%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  font-size: 18px;
  line-height: 30px;
  letter-spacing: .05ex;
  color: #8f8f8f;
  overflow: hidden;
  font-style: italic;
  position: relative;
}

.screen__inner__wrapper_nepali {
  height: 270px;
  padding: 0 30px;
  border-top: 30px solid gainsboro;
  overflow: hidden;
  opacity: 0;
  font-family: preetinormal;
  
}

.screen__inner__wrapperFadeIn_nepali {
  height: 270px;
  padding: 0 30px;
  font-family: 'Roboto Mono', monospace;
  overflow: hidden;
  opacity: 18;
  font-size: 1px;
  transition: opacity 1s;
  font-size: 25px;
  font-family: preetinormal;
  font-weight: 600;
}

.screen__inner__num {
  text-align: center;
  width: 100px;
  margin: auto;
}

.screen__inner__sym {
  font-size: 25px;
  word-spacing: 20px;
  line-height: 40px;
}

.progressMeter {
  border: 2px solid #a4b9d3;
  border-radius: 200px;
  position: absolute;
  display: block;
  padding: 0 5px 0 5px;
  bottom: 4px;
  right: 15px;
  font-size: 14px;
  color: #a4b9d3;
  font-family: auto;
}


.completedText {
  color: #00652c;
}

.progressMarker {
  position: relative;
  top: 5px;
  left: 1px;
}

.progressMarker::before {
  content: ' ';
  height: 20px;
  position: absolute;
  font-style: initial;
  border-left: 1px solid #a7a6a6;
}

.progressMarkerNum::before {
  content: ' ';
  height: 25px;
  position: absolute;
  font-style: initial;
  border-left: 1px solid #a7a6a6;
}

.progressMarkerSym::before {
  content: ' ';
  height: 30px;
  position: absolute;
  font-style: initial;
  border-left: 1px solid #a7a6a6;
}

.remainingText::first-letter {
  color: blue
}

@font-face {
  font-family: preetinormal;
  src: url(/static/media/preetynormal.7e34e69a.otf) format("opentype");
}
#normal_key {
  font-family: auto;
  font-size: 17px;
}
.keyboard_key_nepali_ii{
  position: relative;
  top: 0px;
  font-family: preetinormal;
}


.keyboard_nepali {
  background: #0e0f10e0;
  margin: 30px auto;
  padding: 9px;
  text-align: center;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  width: 825px;
  box-sizing: border-box;

}

.keyboard_sidebar{
  display: flex;
}


.keyboard_nepali .keyboard_key_main {
font-size: 11px;
}



.keyboard_nepali .keyboard__key {
  height: 50px;
  background: #35363a;
  text-align: center;
  margin: 3px;
  padding: 6px;
  color: #aba7a7;
  display: inline-flex;
  font-size: 20px;
  border-radius: 5px;
  border-bottom: 3px solid #2b2b2b;
  position: relative;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}
.keyboard__highlightedKey {
  -webkit-animation: incorrect 2s;
          animation: incorrect 2s
}

@-webkit-keyframes incorrect {
  from {
    background: #4c2929;
    border-bottom: 3px solid #601c1c;
  }
  to {
    background: #35363a;
    border-bottom: 3px solid #2b2b2b;
  }
}

@keyframes incorrect {
  from {
    background: #4c2929;
    border-bottom: 3px solid #601c1c;
  }
  to {
    background: #35363a;
    border-bottom: 3px solid #2b2b2b;
  }
}

.keyboard__capsOn {
  box-shadow: 0 0 2px 0 #ff9898;
}

.keyboard__width-plusHalf {
  width: 70px;
}

.keyboard__width-threeQuarts {
  width: 82px;
}

.keyboard__width-plusOne {
  width: 107px;
}

.keyboard__spacebar {
  width: 300px;
  margin: 3px auto;
}

.keyboard__correctKey {
  box-shadow: 0 0 10px 0 #98a9ff;
}




/* ---Sidebar--- */
.rightside {
  float: left;
  width: 350px;
  right: 0;
  margin-top: 40px;
}
.thumpin{
  text-align: center;
}
.rightsideShow {
  background: #2b2c30;
  float: left;
  height: calc(100% - 70px);
  width: 350px;
}


.sidebar__box {
  margin-bottom: 20px;
  box-shadow: -5px 13px 28px rgb(247 247 247 / 16%), 0 10px 10px rgb(0 0 0 / 22%);
}

.sidebar__generator__input {
  height: 50px;
  border: none;
  padding: 20px;
  font-size: 20px;
  letter-spacing: 6px;
  width: 80%;
  display: inline-block;
  vertical-align: top;
  background: #dcdcdc;
  color: #2b2c30;
}
.sidebar__generator__input::-webkit-input-placeholder {
  letter-spacing: 0px;
  color: #9b9da8;
  font-size: 16px;
}
.sidebar__generator__input::placeholder {
  letter-spacing: 0px;
  color: #9b9da8;
  font-size: 16px;
}

.sidebar__generator__button {
  width: 20%;
  height: 50px;
  display: inline-block;
  vertical-align: top;
  font-size: 30px;
  background: #0e69a4;
  color: #fff;
  transition: 0.2s;
}
.sidebar__generator__button:hover {
  cursor: pointer;
  background: #0c5a8e;
  transition: 0.1s;
}

.sidebar__item {
  height: 50px;
  background: #dcdcdc;
  border-top: 1px solid #cbcbcb;
  transition: 0.1s;
}
.sidebar__item:hover {
  background: #cecece;
  cursor: pointer;
  transition: 0.2s;
}

.sidebar__item__p {
  padding: 2px;
  padding-left: 20px;
  color: #2b2c30;
}

.sidebar__switch__option {
  height: 50px;
  display: inline-block;
  width: 33.333%;
  padding: 2px;
  text-align: center;
  background: #dcdcdc;
  transition: 0.1s;
  border-right: 1px solid #cbcbcb;
}
.sidebar__switch__option:hover {
  cursor: pointer;
  background: #cecece;
  transition: 0.1s;
}
.sidebar__switch__option .sidebar__swith {
  height: 40px;
}

.sidebar__tile__h1 {
  background: #4bff0026;
  color: #fff;
  font-size: 14px;
  font-weight: 900;
  line-height: 35px;
  padding: 0 20px;
}

.sidebar {
  width: calc(100% - 40px);
}

.statbox {
  float: left;
  height: 60px;
  width: 25%;
  text-align: center;
  line-height: 24px;
  padding: 8px;
}

*{padding: 0; margin: 0; box-sizing: border-box;}
.home_main {
	background: transparent;
	text-align: center;
	width: 100%;
	height: auto;
	background-size: cover;
	background-attachment: fixed;
	position: relative;
	overflow: hidden;
	border-radius: 0 0 85% 85% / 30%;
	transition: 0.8s;
}
.home_main .overlay{
	width: 100%;
	height: 100%;
	padding: 50px;
	color: #FFF;
	text-shadow: 1px 1px 1px #333;
   background-image: linear-gradient( 135deg, #00a6ffb3 10%, #46a7406b 100%);
    transition: 0.8s;
	
}
.home_main .overlay .heading{
	display: -webkit-inline-box;
}



.home_main .overlay h1 {
	font-family: 'Dancing Script', cursive;
	font-size: 80px;
	margin-bottom: 30px;
}

.home_main .overlay h3, p {
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 30px;
}

.home_main .overlay button {
	border: none;
	outline: none;
	padding: 10px 20px;
	border-radius: 50px;
	color: #333;
	background: #fff;
	margin: 0 10px 50px 0;
	box-shadow: 0 3px 20px 0 #0000003b;
	transition: 0.4s;
}
.home_main .overlay button:hover{
	cursor: pointer;
	padding: 20px;
	background-color: black;
	color: white;
}
.content-box {
	font-family: sans-serif;
	font-size: 16px;
}
.content-box li {
	margin: 15px 0;
}
.main-header{
    color: white;
    width: 100%;
    height:80px;
	color: #FFF;
	text-shadow: 1px 1px 1px #333;
    background-image: linear-gradient( 135deg, rgba(0, 166, 255, 0) 10%, #24aae888 100%);
    transition: 0.5s;
}
.header-text{ 
    color: #FFF;
    font-family: 'Dancing Script', cursive; 
    position: absolute;
    top: 10px;
    left: 10px;
}
.header a li{
    font-size: large;
    list-style: none;
    float: right;
    color: white;
    margin-left: 10px;
    border-bottom: 2px solid #deeadd2e;
    padding: 10px;
    margin-top: 10px;
    margin-right: 25px;
    box-shadow: 12px 1px 28px rgb(0 0 0 / 51%), 15px 17px 5px 5px rgb(0 0 0 / 37%);
}

.header a li:hover{
    transition: 0.1s;
    padding: 15px;
    border-right: 1px solid white;
    border-left: 1px solid white;
    box-shadow: 12px 1px 28px rgb(0 0 0 / 51%), 15px 17px 5px 5px rgb(9 135 173 / 41%);
    -webkit-animation: forwards;
            animation: forwards;
}
.header a:visited{
    color: white;
}
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 5px;
    width: 500px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.583);
    color: white;
  }
.about-section
{
background-size: 55%;
background-color: rgba(0, 0, 0, 0.666);
overflow: hidden;
padding: 100px;
}
.inner-container
{
    color: white;
    float: right;
    background-color: transparent;
    padding: 150px;
}
.inner-container h1
{
    color: white;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 900px;

}
.text
{
    color: white;
    font-size: 13px;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 40px;

}
@media screen and (max-width:1200px){
.inner-container
{
 padding: 80px;

} 
}
@media screen and (max-width:1000px){
    .about-section
    {
        background-size: 100%;
        padding: 100px 40px;
    }
    .inner-container
    {
     width: 100%;
    } 
    }
    @media screen and (max-width:600px){
        .about-section
        {
            padding: 0px;
        }
        .inner-container
        {
         padding: 60px;
        } 
        }

   .form-inner h1 {
    margin-top: 0;
    font-weight: 500;
    color: #fff;
    }
    .form-inner p{
        color: white;
    }
    .decor {
        text-align: center;
    }
    .form-inner {
    padding: 40px;
    background-color: #00000082;
    height: 96vh;
    }
    .form-inner input,
    .form-inner textarea {
    width: 400px;
    padding: 15px;
    margin-bottom: 10px;
    border: none;
    border-radius: 20px;
    background: #d0dfe8;
    }
    .form-inner textarea {
    resize: none;
    }
    .form-inner button {
    width: 200px;
    padding: 10px;
    margin-top: 20px;
    border-radius: 20px;
    border: none;
    border-bottom: 4px solid #3e4f24;
    background: #5a7233; 
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    }
    .form-inner button:hover {
    background: #3e4f24;
    } 

.privacypolicy{
    padding-left: 50px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.522);
    color: white;
}

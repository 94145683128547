@font-face {
  font-family: preetinormal;
  src: url("./preetynormal.otf") format("opentype");
}
#normal_key {
  font-family: auto;
  font-size: 17px;
}
.keyboard_key_nepali_ii{
  position: relative;
  top: 0px;
  font-family: preetinormal;
}


.keyboard_nepali {
  background: #0e0f10e0;
  margin: 30px auto;
  padding: 9px;
  text-align: center;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  width: 825px;
  box-sizing: border-box;

}

.keyboard_sidebar{
  display: flex;
}


.keyboard_nepali .keyboard_key_main {
font-size: 11px;
}



.keyboard_nepali .keyboard__key {
  height: 50px;
  background: #35363a;
  text-align: center;
  margin: 3px;
  padding: 6px;
  color: #aba7a7;
  display: inline-flex;
  font-size: 20px;
  border-radius: 5px;
  border-bottom: 3px solid #2b2b2b;
  position: relative;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}
.keyboard__highlightedKey {
  animation: incorrect 2s
}

@keyframes incorrect {
  from {
    background: #4c2929;
    border-bottom: 3px solid #601c1c;
  }
  to {
    background: #35363a;
    border-bottom: 3px solid #2b2b2b;
  }
}

.keyboard__capsOn {
  box-shadow: 0 0 2px 0 #ff9898;
}

.keyboard__width-plusHalf {
  width: 70px;
}

.keyboard__width-threeQuarts {
  width: 82px;
}

.keyboard__width-plusOne {
  width: 107px;
}

.keyboard__spacebar {
  width: 300px;
  margin: 3px auto;
}

.keyboard__correctKey {
  box-shadow: 0 0 10px 0 #98a9ff;
}


   .form-inner h1 {
    margin-top: 0;
    font-weight: 500;
    color: #fff;
    }
    .form-inner p{
        color: white;
    }
    .decor {
        text-align: center;
    }
    .form-inner {
    padding: 40px;
    background-color: #00000082;
    height: 96vh;
    }
    .form-inner input,
    .form-inner textarea {
    width: 400px;
    padding: 15px;
    margin-bottom: 10px;
    border: none;
    border-radius: 20px;
    background: #d0dfe8;
    }
    .form-inner textarea {
    resize: none;
    }
    .form-inner button {
    width: 200px;
    padding: 10px;
    margin-top: 20px;
    border-radius: 20px;
    border: none;
    border-bottom: 4px solid #3e4f24;
    background: #5a7233; 
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    }
    .form-inner button:hover {
    background: #3e4f24;
    } 

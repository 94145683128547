

/* ---Sidebar--- */
.rightside {
  float: left;
  width: 350px;
  right: 0;
  margin-top: 40px;
}
.thumpin{
  text-align: center;
}
.rightsideShow {
  background: #2b2c30;
  float: left;
  height: calc(100% - 70px);
  width: 350px;
}


.sidebar__box {
  margin-bottom: 20px;
  box-shadow: -5px 13px 28px rgb(247 247 247 / 16%), 0 10px 10px rgb(0 0 0 / 22%);
}

.sidebar__generator__input {
  height: 50px;
  border: none;
  padding: 20px;
  font-size: 20px;
  letter-spacing: 6px;
  width: 80%;
  display: inline-block;
  vertical-align: top;
  background: #dcdcdc;
  color: #2b2c30;
}
.sidebar__generator__input::placeholder {
  letter-spacing: 0px;
  color: #9b9da8;
  font-size: 16px;
}

.sidebar__generator__button {
  width: 20%;
  height: 50px;
  display: inline-block;
  vertical-align: top;
  font-size: 30px;
  background: #0e69a4;
  color: #fff;
  transition: 0.2s;
}
.sidebar__generator__button:hover {
  cursor: pointer;
  background: #0c5a8e;
  transition: 0.1s;
}

.sidebar__item {
  height: 50px;
  background: #dcdcdc;
  border-top: 1px solid #cbcbcb;
  transition: 0.1s;
}
.sidebar__item:hover {
  background: #cecece;
  cursor: pointer;
  transition: 0.2s;
}

.sidebar__item__p {
  padding: 2px;
  padding-left: 20px;
  color: #2b2c30;
}

.sidebar__switch__option {
  height: 50px;
  display: inline-block;
  width: 33.333%;
  padding: 2px;
  text-align: center;
  background: #dcdcdc;
  transition: 0.1s;
  border-right: 1px solid #cbcbcb;
}
.sidebar__switch__option:hover {
  cursor: pointer;
  background: #cecece;
  transition: 0.1s;
}
.sidebar__switch__option .sidebar__swith {
  height: 40px;
}

.sidebar__tile__h1 {
  background: #4bff0026;
  color: #fff;
  font-size: 14px;
  font-weight: 900;
  line-height: 35px;
  padding: 0 20px;
}

.sidebar {
  width: calc(100% - 40px);
}

.statbox {
  float: left;
  height: 60px;
  width: 25%;
  text-align: center;
  line-height: 24px;
  padding: 8px;
}

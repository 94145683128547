* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
}
body{
  background-image: url(./background.jpg);
  margin: 0px;
  padding: 0px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-tap-highlight-color: transparent;
}

ol, ul {
  list-style: none;
}

p, h1, h2, h3, h4, h5, h6, a {
  font-family: Arial, sans-serif;
  font-weight: normal;
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:focus, select:focus, textarea:focus, button:focus {
  outline: none;
}

button {
  border: none;
}





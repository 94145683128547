.main-header{
    color: white;
    width: 100%;
    height:80px;
	color: #FFF;
	text-shadow: 1px 1px 1px #333;
    background-image: linear-gradient( 135deg, rgba(0, 166, 255, 0) 10%, #24aae888 100%);
    transition: 0.5s;
}
.header-text{ 
    color: #FFF;
    font-family: 'Dancing Script', cursive; 
    position: absolute;
    top: 10px;
    left: 10px;
}
.header a li{
    font-size: large;
    list-style: none;
    float: right;
    color: white;
    margin-left: 10px;
    border-bottom: 2px solid #deeadd2e;
    padding: 10px;
    margin-top: 10px;
    margin-right: 25px;
    box-shadow: 12px 1px 28px rgb(0 0 0 / 51%), 15px 17px 5px 5px rgb(0 0 0 / 37%);
}

.header a li:hover{
    transition: 0.1s;
    padding: 15px;
    border-right: 1px solid white;
    border-left: 1px solid white;
    box-shadow: 12px 1px 28px rgb(0 0 0 / 51%), 15px 17px 5px 5px rgb(9 135 173 / 41%);
    animation: forwards;
}
.header a:visited{
    color: white;
}
.about-section
{
background-size: 55%;
background-color: rgba(0, 0, 0, 0.666);
overflow: hidden;
padding: 100px;
}
.inner-container
{
    color: white;
    float: right;
    background-color: transparent;
    padding: 150px;
}
.inner-container h1
{
    color: white;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 900px;

}
.text
{
    color: white;
    font-size: 13px;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 40px;

}
@media screen and (max-width:1200px){
.inner-container
{
 padding: 80px;

} 
}
@media screen and (max-width:1000px){
    .about-section
    {
        background-size: 100%;
        padding: 100px 40px;
    }
    .inner-container
    {
     width: 100%;
    } 
    }
    @media screen and (max-width:600px){
        .about-section
        {
            padding: 0px;
        }
        .inner-container
        {
         padding: 60px;
        } 
        }

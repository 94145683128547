*{padding: 0; margin: 0; box-sizing: border-box;}
.home_main {
	background: transparent;
	text-align: center;
	width: 100%;
	height: auto;
	background-size: cover;
	background-attachment: fixed;
	position: relative;
	overflow: hidden;
	border-radius: 0 0 85% 85% / 30%;
	transition: 0.8s;
}
.home_main .overlay{
	width: 100%;
	height: 100%;
	padding: 50px;
	color: #FFF;
	text-shadow: 1px 1px 1px #333;
   background-image: linear-gradient( 135deg, #00a6ffb3 10%, #46a7406b 100%);
    transition: 0.8s;
	
}
.home_main .overlay .heading{
	display: -webkit-inline-box;
}



.home_main .overlay h1 {
	font-family: 'Dancing Script', cursive;
	font-size: 80px;
	margin-bottom: 30px;
}

.home_main .overlay h3, p {
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 30px;
}

.home_main .overlay button {
	border: none;
	outline: none;
	padding: 10px 20px;
	border-radius: 50px;
	color: #333;
	background: #fff;
	margin: 0 10px 50px 0;
	box-shadow: 0 3px 20px 0 #0000003b;
	transition: 0.4s;
}
.home_main .overlay button:hover{
	cursor: pointer;
	padding: 20px;
	background-color: black;
	color: white;
}
.content-box {
	font-family: sans-serif;
	font-size: 16px;
}
.content-box li {
	margin: 15px 0;
}
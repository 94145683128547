/* ---Screen--- */
@font-face {
  font-family: preetinormal;
  src: url("./preetynormal.otf") format("opentype");
}

.screen_nepali{
  height: 300px;
  width: 100%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  font-size: 18px;
  line-height: 30px;
  letter-spacing: .05ex;
  color: #8f8f8f;
  overflow: hidden;
  font-style: italic;
  position: relative;
}

.screen__inner__wrapper_nepali {
  height: 270px;
  padding: 0 30px;
  border-top: 30px solid gainsboro;
  overflow: hidden;
  opacity: 0;
  font-family: preetinormal;
  
}

.screen__inner__wrapperFadeIn_nepali {
  height: 270px;
  padding: 0 30px;
  font-family: 'Roboto Mono', monospace;
  overflow: hidden;
  opacity: 18;
  font-size: 1px;
  transition: opacity 1s;
  font-size: 25px;
  font-family: preetinormal;
  font-weight: 600;
}

.screen__inner__num {
  text-align: center;
  width: 100px;
  margin: auto;
}

.screen__inner__sym {
  font-size: 25px;
  word-spacing: 20px;
  line-height: 40px;
}

.progressMeter {
  border: 2px solid #a4b9d3;
  border-radius: 200px;
  position: absolute;
  display: block;
  padding: 0 5px 0 5px;
  bottom: 4px;
  right: 15px;
  font-size: 14px;
  color: #a4b9d3;
  font-family: auto;
}


.completedText {
  color: #00652c;
}

.progressMarker {
  position: relative;
  top: 5px;
  left: 1px;
}

.progressMarker::before {
  content: ' ';
  height: 20px;
  position: absolute;
  font-style: initial;
  border-left: 1px solid #a7a6a6;
}

.progressMarkerNum::before {
  content: ' ';
  height: 25px;
  position: absolute;
  font-style: initial;
  border-left: 1px solid #a7a6a6;
}

.progressMarkerSym::before {
  content: ' ';
  height: 30px;
  position: absolute;
  font-style: initial;
  border-left: 1px solid #a7a6a6;
}

.remainingText::first-letter {
  color: blue
}
